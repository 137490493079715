import * as React from "react";
import type { HeadFC } from "gatsby";
import { Link } from "gatsby";
import styled from "styled-components";

import { Container } from "../components/Grid";
import { Layout } from "../components/Layout";

const ScMain = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #020203;

  .post-full-content {
    background-color: transparent;
  }
`;

const LegalPage = () => {
  return (
    <Layout>
      <ScMain>
        <Container>
          <article className="post-full post no-image page no-image">
            <h1>Legal</h1>
            <section className="post-full-content">
              <div className="post-content">
                <p>
                  <em>
                    User Agreements and Privacy Policies can be found here.
                  </em>
                </p>
                <h3 id="legal-and-privacy-policies">
                  Legal and Privacy Policies
                </h3>
                <ul>
                  <li>
                    <Link to="/terms-of-service-v5">Terms of Service</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy-v1/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/api-license-agreement/">
                      API License Agreement
                    </Link>
                  </li>
                  <li>
                    <Link to="/cookie-policy/">Cookie Policy</Link>
                  </li>
                </ul>
                <h3 id="risk-disclosure">Risk Disclosure</h3>
                <ul>
                  <li>
                    <Link to="/risk-disclosure-statement/">
                      Risk Disclosure Statement
                    </Link>
                  </li>
                </ul>
                <h3 id="security">Security</h3>
                <ul>
                  <li>
                    <Link to="/security-policy/">Security Policy</Link>
                  </li>
                </ul>
              </div>
            </section>
          </article>
        </Container>
      </ScMain>
    </Layout>
  );
};

export default LegalPage;

export const Head: HeadFC = () => <title>Contact Us</title>;
